/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { useEffect } from "react";
import Typewriter from 'typewriter-effect/dist/core'
import GraphemeSplitter from "grapheme-splitter";

export const Typer = ({ strings, fontFamily, fontWeight })  => {
    const stringSplitter = string => {
        const splitter = new GraphemeSplitter();
        return splitter.splitGraphemes(string);
      };

    useEffect(() => {
        let typed = new Typewriter(".typed", {
            loop: true,
            autoStart: true,
            stringSplitter,
            strings: strings,
            deleteSpeed: 10,
          }
        );
        typed
        .start()
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <Text className='typed' sx={{fontFamily:`${fontFamily}`, fontWeight:`${fontWeight}`}}/>
    );
};